import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store as NgrxStore } from '@ngrx/store';
import { initFbConnectRemovalABTestCampaign } from '@stores/abtest/abtest-ngrx.action';

export const initFbConnectRemovalAbTestGuard: CanActivateFn = () => {
  inject(NgrxStore).dispatch(initFbConnectRemovalABTestCampaign());

  return true;
};
