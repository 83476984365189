import { createAction, props } from '@ngrx/store';

interface abtestValue {
  value: string;
}

export const initMoneySignupWordingABTestCampaign = createAction(
  '[AbTest] Init Money signup wording ab test campaigns'
);
export const initABTestCampaignsSuccess = createAction(
  '[AbTest] Init Money signup wording ab test campaigns success',
  props<{ abtestValue: abtestValue }>()
);

export const initFbConnectRemovalABTestCampaign = createAction('[AbTest] Init FB connect removal ab test campaigns');
export const initFbConnectRemovalABTestCampaignSuccess = createAction(
  '[AbTest] Init FB connect removal ab test campaigns success',
  props<{ abtestValue: abtestValue }>()
);
